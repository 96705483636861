<template>
    <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Users
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="!statusDialog && !resetDialog">
            <v-row class="subtitle-2" v-if="$vuetify.breakpoint.mdAndUp">
                <v-col cols="6">
                    USER
                </v-col>
                <v-col cols="6" class="text-right">
                    ACTIONS
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-for="user in getUserList" :key="user.user" class="entries text-center text-md-left" align="center">
                <v-col cols="12" md="6" class="subtitle-1">
                    {{user.user}}
                </v-col>
                <v-col cols="12" md="6" class="text-center text-md-right">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" color="secondary" @click="resetConfirm(user)">
                                <v-icon>fas fa-sync</v-icon>
                            </v-btn>
                        </template>
                        <span>Send User Password Reset</span>
                    </v-tooltip>
                    
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" :color="user.status ? 'secondary' : 'accent'" @click="changeStatusConfirm(user)">
                                <v-icon>{{user.status ? 'fas fa-user-slash' : 'fas fa-user-check'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{user.status ? 'Disable User' : 'Enable User'}}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- STATUS DIALOG -->
        <v-card-text v-if="statusDialog">
            <v-row>
                <v-col cols="12" md="6" offset-md="3">
                    <v-row>
                        <v-col cols="12" class="text-center title">Are you sure you want to {{selectedUser.status ? 'disable' : 'enable'}} {{selectedUser.user}}?</v-col>
                    </v-row>
                    <v-row align="center" justify="space-around">
                        <v-btn outlined color="red" @click="close()">
                            <v-icon left>fas fa-times</v-icon>
                            Cancel
                        </v-btn>
                        <v-btn outlined @click="changeUserStatus()">
                            <v-icon left>fas fa-check</v-icon>
                            Continue
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- RESET DIALOG -->
        <v-card-text v-if="resetDialog">
            <v-row>
                <v-col cols="12" md="6" offset-md="3">
                    <v-row>
                        <v-col cols="12" class="text-center title">Are you sure you want to send a reset password link to {{selectedUser.user}}?</v-col>
                    </v-row>
                    <v-row align="center" justify="space-around">
                        <v-btn outlined color="red" @click="close()">
                            <v-icon left>fas fa-times</v-icon>
                            Cancel
                        </v-btn>
                        <v-btn outlined @click="sendPasswordReset()">
                            <v-icon left>fas fa-check</v-icon>
                            Continue
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data(){
        return {
            resetDialog:    false,
            selectedUser:   {},
            statusDialog:   false,
        }
    },
    methods: {
        changeUserStatus(){
            let userData = {
                id      : this.selectedUser.id,
                user    : this.selectedUser.user,
                status  : !this.selectedUser.status
            } 
            this.$store.dispatch('changeUserStatus', userData)
            this.close()
        },
        changeStatusConfirm(user){
            this.selectedUser   = user
            this.statusDialog   = true
        },
        close(){
            this.resetDialog    = false
            this.statusDialog   = false
            this.selectedUser   = {}
        },
        resetConfirm(user){
            this.selectedUser   = user
            this.resetDialog    = true
        },
        sendPasswordReset(){
            this.$store.dispatch('sendPasswordReset', this.selectedUser)
            this.close()
        }
    },
    computed: {
        getUserList(){
            return this.$store.getters.getUserList
        }
    }
}
</script>

<style scoped>
.entries:nth-child(odd){
    background: #d7dae5;
}
</style>